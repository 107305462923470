<template>
  <div class="layout_common fault-cycle">
    <div class="main_box">
      <div class="main_box_con">
         <searchHead
          compType="fault-cycle"
          :storeInfo="storeInfo"
          @search="search"
          @reset="search"
          @rankClick="isShow = true"
          @saleArea="saleArea"
        />
        <div class="echart" v-loading="loading">
          <barEchart
            id1="fault_cycle_box"
            id2="fault_cycle_echart"
            :propData="echartData"
            :barColor="['#5470c6', '#fac858']"
          />
        </div>
      </div>
    </div>
     <!-- 商家发货排名 -->
    <rank
      :isShow="isShow"
      @close="isShow = false"
      @selectStore="selectStore"
    />
  </div>
</template>
<script>
import searchHead from '@/views/data-center/components/searchHead'
import rank from '@/views/data-center/components/rank'
import barEchart from '../components/agingBar'

import { faultCycleAnalysis } from '@/api'
import { formatDate, diffDate } from '@/utils/common'
export default {
  components: {
    barEchart,
    searchHead,
    rank
  },
  data () {
    return {
      loading: false,
      isShow: false,
      level: '',
      cityId: '',
      storeId: '',
      storeName: '',
      storeInfo: {},
      customerName: '',
      startTime: '',
      endTime: '',
      cycleType: 1,
      echartData: []
    }
  },
  mounted () {
  },
  methods: {
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.level = level
      this.cityId = cityId
      // 缺省默认6个月
      this.endTime = formatDate(new Date()).slice(0, 7)
      this.startTime = diffDate(-5, 'months', true).slice(0, 7)
      this.getList()
    },
    getList () {
      const params = {
        level: this.level,
        cityId: this.cityId,
        customerName: this.customerName,
        timeType: this.cycleType,
        storeId: this.storeId,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      faultCycleAnalysis(params).then(res => {
        this.loading = false
        const {
          cyclePoorUnpacking,
          cycleJan,
          cycleFeb,
          cycleMar,
          cycleApril,
          cycleMay,
          cycleJune,
          cycleJuly,
          cycleAug,
          cycleSep,
          cycleOct,
          cycleNov,
          cycleDec,
          cycleOth
        } = res.data
        this.echartData = [{
        // name: '报修设备数量',
          dataX: [
            '开箱不良\n(发货6天内的设备数量)',
            '第一月\n(0-30天)',
            '第二月\n(31-60天)',
            '第三月\n(61-90天)',
            '第四月\n(91-120天)',
            '第五月\n(121-150天)',
            '第六月\n(151-180天)',
            '第七月\n(181-210天)',
            '第八月\n(211-240天)',
            '第九月\n(241-270天)',
            '第十月\n(271-300天)',
            '第十一月\n(301-330天)',
            '第十二月\n(331-360天)',
            '大于十二月\n(>360天)'
          ],
          value: [cyclePoorUnpacking,
            cycleJan,
            cycleFeb,
            cycleMar,
            cycleApril,
            cycleMay,
            cycleJune,
            cycleJuly,
            cycleAug,
            cycleSep,
            cycleOct,
            cycleNov,
            cycleDec,
            cycleOth]
        }]
      })
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
      this.storeInfo = {
        storeId: this.storeId,
        storeName: this.storeName
      }
    },
    search (val) {
      this.curPage = 1
      const {
        level,
        cityId,
        customerName,
        storeId,
        cycleType,
        startTime,
        endTime
      } = val
      this.level = level
      this.cityId = cityId
      this.customerName = customerName
      this.storeId = storeId
      this.cycleType = cycleType
      this.startTime = startTime
      this.endTime = endTime
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.fault-cycle {
  .echart {
    margin-top: 20px;
    height: calc(100% - 120px);
  }
}
</style>
